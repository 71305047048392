@import '~bootstrap/dist/css/bootstrap.css';
@import url('https://fonts.googleapis.com/css2?family=Castoro&display=swap');

.App {
  text-align: center;
  /* background-color: #b4b7ba; */
  overflow: hidden;
  font-family: 'Castoro', serif;
  height: 100%;
  background-size: cover;
}

html {
  height: 100vh;
}

body {
  height: 100%;
  background: linear-gradient(
    90deg,
    RGB(39, 58, 66) 0%,
    #b4b7ba 25%,
    RGB(39, 58, 66) 50%,
    #b4b7ba 75%,
    RGB(39, 58, 66) 100%
  );
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav {
  background-color: rgb(120, 13, 13);
  font-size: 1.25rem;
  box-shadow: 0 0 13px 0.25px #daa520;
  /* border-top: 3px solid 	rgb(218,165,32);
  border-bottom: 3px solid 	rgb(218,165,32); */
}

@media (max-width: 767px) {
  .nav {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .nav {
    font-size: 0.77rem;
  }
}

@media (max-width: 320px) {
  .nav {
    font-size: 0.57rem;
  }
}

@media (max-width: 280px) {
  .nav {
    font-size: 0.4rem;
  }
}

#navLink {
  font-weight: bold;
  color: #f0f1f1;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 0px solid #bfa552;
}

#navLink:hover {
  transition: all 0.2 ease-in-out;
  transform: scale(1.1);
  color: #f0f1f1;
  border-bottom: 2px solid #bfa552;
  /* border-top: 2px solid #bfa552; */
}

.footer {
  margin-top: 68px;
}

.divider {
  margin: 0;
  padding-top: 6px;
  padding-bottom: 0px;
  font-weight: bold;
  color: #bfa552;
}

.centerLogo {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.logo {
  height: 9em;
  width: 41em;
  max-width: 41rem;
  filter: drop-shadow(7px 3px 4px #bfa552);
}

@media (max-width: 1920px) {
  .logo {
    max-width: 100%;
    height: 100%;
  }
}

@media (max-width: 1351px) {
  .logo {
    max-width: 100%;
    height: 100%;
  }
}

@media (max-width: 1350px) {
  .logo {
    max-width: 100%;
    height: 100%;
  }
}

@media (max-width: 1280px) {
  .logo {
    max-width: 40em;
    height: 9em;
  }
}

@media (max-width: 1024px) {
  .logo {
    max-width: 100%;
    height: 90%;
  }
}

@media (max-width: 992px) {
  .logo {
    max-width: 90%;
    height: 85%;
  }
}

@media (max-width: 800px) {
  .logo {
    max-width: 65%;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .logo {
    max-width: 85%;
    height: 85%;
  }
}

@media (max-width: 767px) {
  .logo {
    max-width: 80%;
    height: 85%;
  }
}

@media (max-width: 480px) {
  .logo {
    max-width: 100%;
    height: 90%;
  }
}

@media (max-width: 375px) {
  .logo {
    max-width: 95%;
    height: 90%;
  }
}

@media (max-width: 320px) {
  .logo {
    max-width: 90%;
    height: 85%;
  }
}

@media (max-width: 320px) {
  .logo {
    max-width: 100%;
    height: 85%;
  }
}

.logoTwo {
  color: #bfa552;
  letter-spacing: 2;
  text-shadow: 0 0 0.6rem #fffde6, 0 0 1.5rem #fff065,
    -0.2rem 0.1rem 1rem #fcff65, 0.2rem 0.1rem 1rem #fffc65,
    0 -0.5rem 2rem #fbff24, 0 0.5rem 3rem #fffb24;
  animation: shine 2s forwards, flicker 3s infinite;
}

@media (max-width: 1201px) {
  .logoTwo {
    font-size: 2rem;
  }
}

@media (max-width: 1024px) {
  .logoTwo {
    font-size: 2rem;
  }
}

@media (max-width: 800px) {
  .logoTwo {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .logoTwo {
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .logoTwo {
    font-size: 1.75rem;
  }
}

@media (max-width: 540px) {
  .logoTwo {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .logoTwo {
    font-size: 1.35rem;
  }
}

@media (max-width: 375px) {
  .logoTwo {
    font-size: 1.15rem;
  }
}

@media (max-width: 320px) {
  .logoTwo {
    font-size: 1rem;
  }
}

@media (max-width: 280px) {
  .logoTwo {
    font-size: 0.8rem;
  }
}

@keyframes shine {
  0% {
    color: #bfa552;
    text-shadow: none;
  }
  100% {
    color: #fffde6;
    text-shadow: 0 0 0.6rem #fffde6, 0 0 1.5rem #fff065,
      -0.2rem 0.1rem 1rem #fcff65, 0.2rem 0.1rem 1rem #fffc65,
      0 -0.5rem 2rem #fbff24, 0 0.5rem 3rem #fffb24;
  }
}

.flicker {
  animation: shine 1.8s forwards, blink 7.5s 1s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blinkTwo 7.5s 1s infinite;
}

@keyframes blink {
  0%,
  8.33%,
  16.66% {
    color: #bfa552;
    text-shadow: none;
  }
  16.86% {
    color: #fffde6;
    text-shadow: 0 0 0.6rem #fffde6, 0 0 1.5rem #fff065,
      -0.2rem 0.1rem 1rem #fcff65, 0.2rem 0.1rem 1rem #fffc65,
      0 -0.5rem 2rem #fbff24, 0 0.5rem 3rem #fffb24;
  }
  18% {
    color: #bfa552;
    text-shadow: none;
  }
  25%,
  33.32%,
  41.65%,
  50% {
    color: #fffde6;
    text-shadow: 0 0 0.6rem #fffde6, 0 0 1.5rem #fff065,
      -0.2rem 0.1rem 1rem #fcff65, 0.2rem 0.1rem 1rem #fffc65,
      0 -0.5rem 2rem #fbff24, 0 0.5rem 3rem #fffb24;
  }
}

@keyframes blinkTwo {
  0%,
  8.33%,
  16.66%,
  25%,
  33.32%,
  41.65%,
  50% {
    color: #fffde6;
    text-shadow: 0 0 0.6rem #fffde6, 0 0 1.5rem #fff065,
      -0.2rem 0.1rem 1rem #fcff65, 0.2rem 0.1rem 1rem #fffc65,
      0 -0.5rem 2rem #fbff24, 0 0.5rem 3rem #fffb24;
  }
  58.31%,
  66.64% {
    color: #a18d48;
    text-shadow: none;
  }
  75% {
    color: #fffde6;
    text-shadow: 0 0 0.6rem #fffde6, 0 0 1.5rem #fff065,
      -0.2rem 0.1rem 1rem #fcff65, 0.2rem 0.1rem 1rem #fffc65,
      0 -0.5rem 2rem #fbff24, 0 0.5rem 3rem #fffb24;
  }
}
@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}

.cntCardHeader {
  border: 2px solid #bfa552;
  color: #bfa552;
  font-size: 1.5rem;
  font-weight: 500;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: 1.5px 1.5px black;
}

.cntCard {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.623) !important;
}

.cForm {
  border: 2px solid #bfa552;
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  background-color: #fcfbf7;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.623) !important;
}

.cFormTitle {
  color: #bfa552;
  font-weight: 500;
}

.cntCardBody {
  border: 1.5px solid #6a6e73;
  background-color: #fcfbf7;
  border-radius: 0 0 0.25rem 0.25rem;
}

.abCardTitle {
  border: 2px solid #bfa552;
  color: #bfa552;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: 1.5px 1.5px black;
}

.abCardHeader {
  border: 1.5px solid #6a6e73;
  color: #fbfbfb;
  font-size: 1.25rem;
  font-weight: 500;
}

.abCard {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.623) !important;
}

.abCardBody {
  border: 2px solid #bfa552;
  background-color: #fcfbf7;
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.623) !important;
  text-indent: 1.5em;
}

#breakStyle {
  /* border-top: 2px solid #8c1414; */
  height: 2px;
  color: #8c1414;
  border-width: 0;
  opacity: 1;
}

.svcCardTitle {
  border: 2px solid #bfa552;
  color: #bfa552;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: 1.5px 1.5px black;
}

.svcCardHeader {
  border: 2.5px solid #bfa552;
  color: #bfa552;
  font-size: 1.25rem;
}

.svcHeaderBody {
  border: 2px solid#bfa552;
  max-height: 27rem;
  background-color: #fcfbf7;
  border-radius: 0 0 0.25rem 0.25rem;
}

#svcCardBody {
  border: 2px solid#bfa552;
  background-color: #fcfbf7;
  border-radius: 0 0 0.25rem 0.25rem;
}

.svcCard {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.623) !important;
}

.svcQueryCard {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.623) !important;
}

@media (max-width: 1199px) {
  #colQuery {
    margin-top: 1.5rem;
  }
}

@media (max-width: 992px) {
  #colQuery {
    margin-top: 1.5rem;
  }
}

.svcFirstCard {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.623) !important;
}

.cntCardBody {
  border: 2px solid #bfa552;
  background-color: #fcfbf7;
  border-radius: 0 0 0.25rem 0.25rem;
}

.listG {
  min-height: 21rem;
  max-height: 21rem;
  overflow: scroll;
}

.exitBtn {
  color: rgb(148, 10, 10);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border: none;
  background-color: transparent;
}

#customBtn {
  color: rgb(148, 10, 10);
  border: 2px solid #8c1414;
  background-color: #bfa552;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#customBtn:hover {
  color: #fbfbfb;
  background-color: rgb(148, 10, 10);
  border-color: #daa520;
}

.parCardImg {
  width: 15rem;
  height: 10rem;
  background-color: #e1e2e4;
}

.parCard {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.623) !important;
}

.parCardBody {
  background-color: #fcfbf7;
  text-indent: 1.5em;
}

.prodTitle {
  color: #bfa552;
  background-color: #fcfbf7;
}

.card-link {
  color: #585c5f;
  text-decoration: italics;
}

.card-link:hover {
  color: rgb(148, 10, 10);
}

.foot-effect {
  transition: border-width 0.6s linear;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2rem;
  overflow: hidden;
  background: white;
  background-color: rgb(120, 13, 13);
  color: #f0f1f1;
  box-shadow: 0 -3px 13px 0.25px #bfa552;
}

.listGItem {
  background-color: #e1e2e4;
}

.jumbo {
  /* background: linear-gradient(-45deg, #e1e2e4, grey, #d2d4d6, #585c5f); */
  /* background: linear-gradient(-45deg, #f0f1f1, grey, #d2d4d6, #585c5f); */
  /* background: linear-gradient(-45deg, #e4d9b5, #a68d3d, #d7c894, #bfa552);
	background-size: 400% 400%;
  animation: gradient 15s ease infinite; */
  background: url(./images/cStitchBg.png);
  color: #eee;
  width: 100%;
  height: 28vh;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  background-origin: 50% 50%;
  animation: animation 175s linear infinite;
  background-size: cover;
  overflow: hidden;
}

@media (max-width: 1920px) {
  .jumbo {
    height: 28vh;
  }
}

@media (max-width: 1351px) {
  .jumbo {
    height: 26vh;
  }
}

@media (max-width: 1350px) {
  .jumbo {
    height: 26vh;
  }
}

@media (max-width: 1201px) {
  .jumbo {
    height: 28vh;
  }
}

@media (max-width: 1024px) {
  .jumbo {
    height: 27vh;
  }
}

@media (max-width: 992px) {
  .jumbo {
    height: 27vh;
  }
}

@media (max-width: 768px) {
  .jumbo {
    height: 25vh;
  }
}

@media (max-width: 767px) {
  .jumbo {
    height: 23vh;
  }
}

@media (max-width: 540px) {
  .jumbo {
    height: 21vh;
  }
}

@media (max-width: 480px) {
  .jumbo {
    height: 20vh;
  }
}

@media (max-width: 479px) {
  .jumbo {
    height: 20vh;
  }
}

@media (max-width: 375px) {
  .jumbo {
    height: 18vh;
  }
}

@media (max-width: 320px) {
  .jumbo {
    height: 18vh;
  }
}

@media (max-width: 280px) {
  .jumbo {
    height: 14vh;
  }
}

@keyframes animation {
  100% {
    background-position: 0px -3000px;
  }
}

.logoBg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 75%;
  animation: flash 7.5s linear infinite;
  height: 100%;
}

#homeCont {
  border: 2px solid #bfa552;
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.623) !important;
}

.imgThumb {
  background-color: #b4b7ba;
}

.fab {
  color: #8c1414;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes flash {
  0% {
    background-image: url(./images/blankLte.png);
  }
  8.33% {
    background-image: url(./images/newLte.png);
  }
  16.66% {
    background-image: url(./images/blankLte.png);
  }

  25% {
    background-image: url(./images/newLte.png);
  }
  33.32% {
    background-image: url(./images/blankLte.png);
  }
  41.65% {
    background-image: url(./images/blankLte.png);
  }

  50% {
    background-image: url(./images/blankLte.png);
  }
  58.31% {
    background-image: url(./images/blankLte.png);
  }
  66.64% {
    background-image: url(./images/lteFlip.png);
  }

  75% {
    background-image: url(./images/blankLte.png);
  }
  83.3% {
    background-image: url(./images/blankLte.png);
  }
  91.63% {
    background-image: url(./images/blankLte.png);
  }
  100% {
    background-image: url(./images/blankLte.png);
  }
}
